import { defineStore } from 'pinia'
import MenusService from '../../../services/menus.service'
const _MenusService = new MenusService()

export const useMenusStore = defineStore('menusStore', {
  state: () => ({
    menus_flitrados: [],
    params: {
      limit: 10,
      offset: 0,
      page: 0,
      name: ''
    },
    menus_paginados: {}
  }),
  getters: {
    _menus_flitrados: state => state.menus_flitrados,
    _params: state => state.params,
    _menus_paginados: state => state.menus_paginados
  },
  actions: {
    buscarMenus (name) {
      _MenusService.search(name).then(({ data }) => {
        data.map(e => {
          if (e.menusHijos.length) {
            e.menusHijos.sort((a, b) => {
              return a.order - b.order
            })
          }
        })
        this.menus_flitrados = data
      })
    },
    paginarMenus () {
      return _MenusService.paginate(this._params).then(({ data }) => {
        this.menus_paginados = data
        this.menus_paginados.rows = data.rows.map(a => {
          a.nombre_permiso_padre = a.menuPadre ? a.menuPadre.name : null
          a.nombre_servicio_padre = a.servicioPadre ? a.servicioPadre.name : null
          return a
        })
        return data
      })
    },
    crearMenus (payload) {
      return _MenusService.crear(payload).then(({ data }) => {
        return data
      })
    },
    find (id) {
      return _MenusService.find(id).then(({ data }) => {
        if (data.menusHermanos.length) {
          data.menusHermanos.sort((a, b) => {
            return a.order - b.order
          })
        }
        return data
      })
    },
    editarMenus (payload) {
      return _MenusService.editar(payload).then(({ data }) => {
        return data
      })
    }
  }
})
