import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_USUARIOS

export default class MenusService {
  getPpal () {
    return http.get(`${baseUrl}/menu/listar-menus-principales`, {
      headers: {
        loading: true
      }
    })
  }

  get () {
    return http.get(`${baseUrl}/menu/listar-menus`, {
      headers: {
        loading: true
      }
    })
  }

  crear (payload) {
    return http.post(`${baseUrl}/menu/crear`, payload, {
      headers: {
        loading: true
      }
    })
  }

  editar (params) {
    return http.put(`${baseUrl}/menu/editar`, params, {
      headers: {
        loading: true
      }
    })
  }

  search (name) {
    return http.get(`${baseUrl}/menu/search-menu/${name}`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/menu/menus-paginado`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/menu/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
