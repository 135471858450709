<template>
  <section>
    <div class="w-full">
      <Toast />
      <DataTable
        :value="listadoMenus.rows"
        tableStyle="min-width: 50rem"
        class="mt-4"
        dataKey="id"
        responsiveLayout="scroll"
      >
        <Column field="id" class="text-sm" header="ID"></Column>
        <Column field="icon" class="text-sm" header="Icono" filterField="icon">
          <template #body="slotProps">
            <div>
              <i class="pi" :class="'pi-' + slotProps.data.icon"></i>
            </div>
          </template>
        </Column>
        <Column field="name" class="text-sm" header="Nombre" filterField="name"></Column>
        <Column field="action" class="text-sm" header="Action"></Column>
        <Column field="nombre_permiso_padre" class="text-sm" header="Permiso padre"></Column>
        <Column field="nombre_servicio_padre" class="text-sm" header="Servicio padre"></Column>
        <Column field="edicion" class="text-sm text-center" header="Acciones">
          <template #body="slotProps">
            <div class="w-full flex justify-center">
              <Button @click="abrirModalEditarMenu(slotProps.data)" icon="pi pi-pencil" text rounded></Button>
            </div>
          </template>
        </Column>
      </DataTable>
      <Paginator v-model:first="params.offset"
        :rows="params.limit"
        :totalRecords="listadoMenus.count"
        :rowsPerPageOptions="[2,10,20,30,100]"
        @page="onPage($event)"
      />
    </div>
    <Dialog header="Editar menú" :closable="false" v-model:visible="displayModalEditarMenu" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
      <template #header>
        <div class="w-full flex items-center">
          <div class="w-3/12 font-bold text-xl">
            <p>Editar menú</p>
          </div>
          <div class="w-9/12">
            <div v-if="dataMenuEditar.usuarioCreacion" class="w-full justify-end flex italic text-gray-600 text-xs">
              <p>Creado por: {{ dataMenuEditar.usuarioCreacion.persona.full_name }} el dia {{ dayjs(dataMenuEditar.createdAt).format('DD-MM-YYYY h:mm A') }}</p>
            </div>
            <div v-if="dataMenuEditar.usuarioEdicion" class="w-full justify-end flex italic text-gray-600 text-xs">
              <p>Actualizado por: {{ dataMenuEditar.usuarioEdicion.persona.full_name }} el dia {{ dayjs(dataMenuEditar.updatedAt).format('DD-MM-YYYY h:mm A') }}</p>
            </div>
          </div>
        </div>
      </template>
      <div class="w-full">
        <div class="field w-full my-2">
          <label class="w-full text-sm text-gray-600" for="name">Iconos</label>
          <Dropdown class="w-full rounded-md border-gray-300" v-model="dataMenuEditar.icon" :options="iconos" placeholder="Seleccione" >
            <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                  <div class="flex gap-2 items-center">
                    <i class="pi" :class="'pi-' + slotProps.value"></i>
                    <div>{{slotProps.value}}</div>
                  </div>
                </div>
                <div class="country-item country-item-value" v-else>
                  {{slotProps.placeholder}}
                </div>
            </template>
            <template #option="slotProps">
                <div class="flex gap-2">
                  <i class="pi" :class="'pi-' + slotProps.option"></i>
                  <div>{{slotProps.option}}</div>
                </div>
            </template>
          </Dropdown>
          <span v-if="creandoMenus && !dataMenuEditar.icon" class="text-red-600 text-xs italic">Este campo es requerido</span>
        </div>
        <div class="field w-full my-2">
          <label class="w-full text-sm text-gray-600" for="name">Nombre del {{ dataMenuEditar.servicio ? 'servicio' : 'menú' }}</label>
          <InputText @input="setListadoOrdenamiento(dataMenuEditar.name)" type="text" class="w-full" v-model="dataMenuEditar.name" />
          <span v-if="creandoMenus && !dataMenuEditar.name" class="text-red-600 text-xs italic">Este campo es requerido</span>
        </div>
        <div v-if="!dataMenuEditar.servicio" class="field w-full my-2">
          <label class="w-full text-sm text-gray-600" for="name">Menú padre</label>
          <AutoComplete
            v-model="menuPadreSeleccionado"
            :pt="{ root: { style: 'height: 2.3rem' } }"
            size="small"
            @complete="buscarMenus(menuPadreSeleccionado)"
            @item-select="setMenuPadre"
            :suggestions="listadoMenusFiltrados"
            :forceSelection="true"
            optionLabel="action"
            class="text-xs w-full"
            inputClass="w-full"
            panelClass=""
          >
            <template #option="slotProps">
              <div class="flex align-options-center">
                <div class="text-sm pr-1">
                  <div class="flex flex-wrap gap-2"><p style="text-wrap: balance" class="">Nombre: {{ slotProps.option.name }}</p></div>
                  <div class="flex flex-wrap gap-2"><p style="text-wrap: balance">Action: {{ slotProps.option.action }}</p></div>
                </div>
              </div>
            </template>
          </AutoComplete>
          <span v-if="creandoMenus && !menuPadreSeleccionado" class="text-red-600 text-xs italic">Este campo es requerido</span>
        </div>
        <div>
          <label class="w-full text-sm text-gray-600" for="name">Action</label>
          <InputText type="text" class="w-full" v-model="dataMenuEditar.action" />
          <span v-if="creandoMenus && !dataMenuEditar.action" class="text-red-600 text-xs italic">Este campo es requerido</span>
        </div>
        <div v-if="listadoOrdenamientosMenusHijos.length">
          <label class="w-full text-sm text-gray-600" for="name">Ordenamiento</label>
          <div class="w-full h-56 overflow-auto">
            <DataTable :value="listadoOrdenamientosMenusHijos" :rowClass="rowClass" class="p-datatable-sm text-xs" :reorderableColumns="true" @rowReorder="onRowReorder">
              <Column rowReorder headerStyle="width: 3rem" :reorderableColumn="false" />
              <Column v-if="!dataMenuEditar.servicio" field="icon" header="">
                <template #body="slotProps">
                  <i class="pi" :class="'pi-' + slotProps.data.icon"></i>
                </template>
              </Column>
              <Column field="name" header=""></Column>
              <Column field="order" header=""></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="cerrarMenuEditar()" class="p-button-text"/>
          <Button label="Guardar" icon="pi pi-check" @click="editarMenu()" autofocus />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useMenusStore } from '../stores/menus.store'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
export default {
  name: 'listadoMenus',
  setup () {
    // Stores
    const menusStore = useMenusStore()
    // Referencias
    const displayModalEditarMenu = ref(false)
    const dataMenuEditar = ref({
      icon: '',
      name: '',
      action: '',
      parent_menu_id: null,
      order: 0,
      state: true,
      nivel: 0,
      servicio: false,
      ordenamiento_hermanos: []
    })
    const menuPadreSeleccionado = ref()
    const listadoOrdenamientosMenusHijos = ref([])
    const creandoMenus = ref(false)
    const iconos = ref(['home', 'copy', 'book', 'chart-bar', 'cloud-upload', 'chart-bar', 'wifi', 'wallet', 'users', 'th-large', 'tag', 'table', 'sitemap', 'shopping-cart', 'shield', 'send', 'pencil', 'money-bill', 'map', 'list', 'key', 'info-circle', 'id-card', 'heart', 'file', 'eye', 'external-link', 'dollar', 'desktop', 'comments', 'cog', 'check', 'briefcase', 'bookmark'])
    // Computed
    const listadoMenus = computed(() => menusStore._menus_paginados)
    const params = computed(() => menusStore._params)
    const listadoMenusFiltrados = computed(() => menusStore._menus_flitrados)
    // Metodos
    const onPage = async ({ first, page, pageCount, rows }) => {
      menusStore.$patch((state) => {
        state.params.limit = rows
      })
      menusStore.paginarMenus()
    }
    const abrirModalEditarMenu = (menu) => {
      menusStore.find(menu.id).then((data) => {
        dataMenuEditar.value = data
        dataMenuEditar.value.servicio = !!data.service_id
        delete dataMenuEditar.value.deletedAt
        delete dataMenuEditar.value.service_id
        displayModalEditarMenu.value = true
        menuPadreSeleccionado.value = data.menuPadre
        listadoOrdenamientosMenusHijos.value = data.menusHermanos
      })
    }
    const cerrarMenuEditar = () => {
      dataMenuEditar.value = {
        icon: '',
        name: '',
        action: '',
        parent_menu_id: null,
        order: 0,
        state: true,
        nivel: 0,
        servicio: false,
        ordenamiento_hermanos: []
      }
      displayModalEditarMenu.value = false
      creandoMenus.value = false
    }
    const setMenuPadre = () => {
      dataMenuEditar.value.parent_menu_id = menuPadreSeleccionado.value.id
      dataMenuEditar.value.nivel = menuPadreSeleccionado.value.nivel + 1
      listadoOrdenamientosMenusHijos.value = menuPadreSeleccionado.value.menusHijos
      dataMenuEditar.value.order = menuPadreSeleccionado.value.menusHijos.length + 1
      listadoOrdenamientosMenusHijos.value.push({
        icon: dataMenuEditar.value.icon,
        name: dataMenuEditar.value.name,
        order: menuPadreSeleccionado.value.menusHijos.length + 1,
        state: true,
        nuevo: true,
        id: dataMenuEditar.value.id
      })
      dataMenuEditar.value.ordenamiento_hermanos = listadoOrdenamientosMenusHijos.value
    }
    const rowClass = (data) => {
      return data.id === dataMenuEditar.value.id ? 'fondo-amarillo' : ''
    }
    const onRowReorder = (event) => {
      event.value.map((value, key) => {
        value.order = key + 1
        if (value.id === dataMenuEditar.value.id) dataMenuEditar.value.order = key + 1
      })
      listadoOrdenamientosMenusHijos.value = event.value
      dataMenuEditar.value.ordenamiento_hermanos = event.value
    }
    const buscarMenus = (nombre) => {
      if (nombre.length > 3) menusStore.buscarMenus(nombre)
    }
    const setListadoOrdenamiento = (name) => {
      if (dataMenuEditar.value.servicio) {
        listadoOrdenamientosMenusHijos.value.map(a => {
          if (a.nuevo) a.name = name
        })
      }
    }
    const editarMenu = () => {
      creandoMenus.value = true
      if (!dataMenuEditar.value.action) return Swal.fire('Error', 'Debes completar todos los campos', 'error')
      if (!dataMenuEditar.value.order) return Swal.fire('Error', 'Debes completar todos los campos', 'error')
      if (!dataMenuEditar.value.name) return Swal.fire('Error', 'Debes completar todos los campos', 'error')
      if (!dataMenuEditar.value.nivel) return Swal.fire('Error', 'Debes completar todos los campos', 'error')
      menusStore.editarMenus(dataMenuEditar.value).then(() => {
        Swal.fire(
          'Guardado!',
          'Menú actualizado con éxito!',
          'success'
        ).then(() => {
          creandoMenus.value = false
          menusStore.paginarMenus()
          cerrarMenuEditar()
        })
      })
    }
    onMounted(() => {
      menusStore.paginarMenus()
    })
    return {
      onPage,
      params,
      displayModalEditarMenu,
      listadoMenus,
      abrirModalEditarMenu,
      cerrarMenuEditar,
      dataMenuEditar,
      menuPadreSeleccionado,
      listadoOrdenamientosMenusHijos,
      buscarMenus,
      listadoMenusFiltrados,
      setMenuPadre,
      onRowReorder,
      rowClass,
      creandoMenus,
      editarMenu,
      iconos,
      setListadoOrdenamiento,
      dayjs
    }
  }
}
</script>
<style>
:global(.swal2-container) {z-index: 1000000 !important}
.fondo-amarillo {
  background-color: hsla(56, 100%, 50%, 0.993) !important;
}
</style>
